/**
 * MDXProvider Components to utilise Bootstrap styles.
 *
 * References:
 * - https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxprovider
 * - https://mdxjs.com/docs/using-mdx/#components
 * - https://www.gatsbyjs.com/plugins/gatsby-remark-highlight-code/#gatsby-remark-highlight-code
 * - https://css-tricks.com/working-with-mdx-custom-elements-and-shortcodes/
 * - https://stackoverflow.com/questions/34410681/javascript-what-is-the-opposite-of-the-logical-operator-a-b
 * 
 * p			Paragraph	
 * h1			Heading 1	#
 * h2			Heading 2	##
 * h3			Heading 3	###
 * h4			Heading 4	####
 * h5			Heading 5	#####
 * h6			Heading 6	######
 * thematicBreak	Thematic break	***
 * blockquote		Blockquote	>
 * ul			List		-
 * ol			Ordered list	1.
 * li			List item	
 * table		Table		`---
 * tr			Table row	`This
 * td/th		Table cell	
 * pre			Pre	
 * code			Code	
 * em			Emphasis	_emphasis_
 * strong		Strong		**strong**
 * delete		Delete		~~strikethrough~~
 * inlineCode		InlineCode	
 * hr			Break		---
 * a			Link		<https://mdxjs.com> or [MDX](https://mdxjs.com)
 * img			Image		![alt](https://mdx-logo.now.sh)
 */

import React from 'react'

const components = {
  /** Markdown Components */
  //a: (props) => <span className="link-anim"><a {...props} /></span>,
  //p: (props) => <p style={{ fontSize: "18px", lineHeight: 1.6 }} {...props} />,
  //h1: (props) => <h1 style={{ color: 'red' }} {...props} />,
  blockquote: (props) => <figure className="text-end"><blockquote className="blockquote" {...props} /></figure>,

  wrapper: ({ onlyExcerpt = false, children }) => {
    let updatedChildren = [ ...children ];

    if (onlyExcerpt) {
      updatedChildren = children.filter((child, _) => {
        return child.props && child.props["data-excerpt"];
      });
    }

    if (!onlyExcerpt) {
      updatedChildren = children.filter((child, _) => {
        return !child.props || !child.props["data-excerpt"];
      });
    }

    return (
      <>{updatedChildren}</>
    );
  },

  /** Shortcodes */
  sourceRight: (props) => <figure className="text-end"><figcaption className="blockquote-footer" {...props} /></figure>,
  sourceLeft: (props) => <figure className="text-start"><figcaption className="blockquote-footer" {...props} /></figure>,
}

export default components;
  